<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Produtos</h3>
        </div>
        <div class="card-body">
          <div class="row  d-flex align-items-end">
            <div class="form-group col-md-3">
              <label>Tipo Produto</label>
              <select required type="text" class="form-control" v-model="filtro.tipo_produto_id">
                <option value="">Selecione:</option>
                <option v-for="lista in lista_tipos_produto" :key="lista.id" :value="lista.id">
                  {{ lista.nome }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label>Substancia</label>
              <select required type="text" class="form-control" v-model="filtro.substancias_id">
                <option value="">Selecione:</option>
                <option v-for="lista in lista_substancias" :key="lista.id" :value="lista.id">
                  {{ lista.nome }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label>Tipo Clase </label>
              <select required type="text" class="form-control" v-model="filtro.tipoClasse_id">
                <option value="">Selecione:</option>
                <option v-for="lista in lista_tipos_classe" :key="lista.id" :value="lista.id">
                  {{ lista.nome }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-3">
              <label class="col-md-12">Laboratorio</label>
              <select required type="text" class="form-control" v-model="filtro.laboratorio_id">
                <option value="">Selecione:</option>
                <option v-for="lista in lista_laboratorios" :key="lista.id" :value="lista.id">
                  {{ lista.nome }}
                </option>
              </select>
            </div>
            <!-- <div class="form-group col-md-1">
              <button class="btn btn-success" @click="pesquisar()">
                <i class="fa fa-search search-icon m-0 p-0"></i>
              </button>
            </div> -->
          </div>
          <div class="row ">
            <div class="form-group col-md-9"></div>

            <div class="form-group col-md-1" style="text-align-last: right;">
              <button class="btn btn-success" @click="pesquisar()" text="">
                Pesquisar
              </button>
            </div>

            <div class="form-group col-md-1 " style="text-align-last: right;">
              <b-dropdown id="dropdown-1" variant="info" text="Imprimir">
                <template #button-content>
                  <i class="fa fa-print"></i> Imprimir
                </template>
                <b-dropdown-item @click="gerarExcel()">Excel Valores</b-dropdown-item>
                <b-dropdown-item @click="gerarExcelSubstancias()">Excel Substancias</b-dropdown-item>
                <b-dropdown-item @click="gerarExcelCadastros()">Excel Cadastros</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>




          <b-table :fields="['produto', 'laboratorio','tipo_produto', 'cant_total', 'total_fracao']" :items="produtos"
            :per-page="perPage" :current-page="currentPage" id="produto-table"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless pt-1" show-empty
            empty-text="Nenhum registro encontrado!">

            <template #head(acoes)><span></span></template>
 
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="produtos.length" :per-page="perPage"
            aria-controls="produto-table">
          </b-pagination>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
export default {
  mixins: { fireAlert },
  data() {
    return {

      filtro: {
        substancias_id: null,
        producto_id: null,
        laboratorio_id: null,
        tipoClasse_id: null,
        tipo_produto_id: null,

      },
      pesquisa: {
        tipo: null,
        texto: '',
      },
      currentPage: 1,
      perPage: 10,
      produtos: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Relatorios" }]);
  },
  created() {
    // this.listar_produtos();
    this.listar_substancias(); 
    this.listar_laboratorios();
    this.listar_tipos_classe();

  },
  computed: {

    lista_substancias() {
      return this.$store.state.substancia.lista_substancias;
    },
    lista_tipos_produto() {
      return this.$store.state.produto.lista_tipos_produto;
    },

    lista_produtos() {
      return this.$store.state.produto.lista_produtos;
    },
    lista_laboratorios() {
      return this.$store.state.laboratorio.lista_laboratorios;
    },
    lista_tipos_classe() {
      return this.$store.state.tipoClasse.lista_tipos_classe;
    },

    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },


    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
  },
  methods: {

    async listar_produtos() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("produto/relatorio", this.filtro).finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("produto/listar_produto", "");
        // localStorage.removeItem('produto');

      });
    },
    async listar_substancias() {
      await this.$store.dispatch("substancia/listar_substancias");
      this.$store.dispatch("produto/listar_tipos_produto")

    },
    async listar_laboratorios() {

      this.$store.dispatch("laboratorio/listar_laboratorios");

    },
    async listar_tipos_classe() {
      this.$store.dispatch("tipoClasse/listar_tipos_classe");
    },

    atualizar(value) {
      this.$router.push({ name: "produtoDados", params: { produto_id: value.id } });
      // this.$store.dispatch("configEmpresa/atualizar", value);
      // localStorage.setItem('produto',JSON.stringify(value));
      // this.$store.dispatch("produto/listar_produto", value.id);
    },

    async pesquisar() {
      // this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("produto/relatorio", this.filtro);
      var dados = this.lista_produtos.dados
      for (let i in dados) {
        const element = dados[i];
        var p =
        {
          "produto": dados[i].produto,
          "laboratorio": dados[i].laboratorio,
          "cant_total": dados[i].quantidade_total,
          "total_estoque": dados[i].total_estoque,
          "total_fracao":dados[i].total_fracao,
          "tipo_produto":dados[i].tipo_produto
        }

        this.produtos.push(p)
      }
      console.log(this.produtos)
      // this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara esta Sala no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("produto/bloquear_produto", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
    async gerarExcel() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      console.log(this.filtro);
      await this.$store.dispatch("produto/excel", this.filtro);
      console.log(this.lista_produtos)
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async gerarExcelSubstancias() {
      await this.$store.dispatch("produto/excel_substancias", this.filtro);
    },
    async gerarExcelCadastros() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("produto/excel_cadastros", this.filtro);
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async gerarExcelClasses() {
      await this.$store.dispatch("produto/excel_classes", this.filtro);
    }

  },
};
</script>

.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}}
</style>